import { createGlobalStyle } from "styled-components"
import { devices } from "../config/breakpoints"

const GlobalStyles = createGlobalStyle`
  
  

  :root {
    
    --color-grey-1: #F7F8F9;
    --color-grey-2: #dee0e2;
    --color-grey-3: #767676;
    --color-grey-4: #54585B;
    --color-grey-5: #323639;
    
    --color-black: #000;
    --color-white: #fff;


    --color-primary: var(--color-teal);
    --color-primary-light: var(--color-teal-light);
    --color-primary-dark: var(--color-teal-dark);
    --color-secondary: var(--color-purple);
    --color-secondary-light: var(--color-purple-light);
    --color-secondary-dark: var(--color-purple-dark);
    
    --color-hot-pink: #FF46BB;
    
    
    --color-purple: #5c38bd;
    --color-purple-light: #6C4CC4;
    --color-purple-dark: #5332AA;

    
    --color-error: #E52343;
    

    --color-teal: #1D99B8;
    --color-teal-light: #34A4BF;
    --color-teal-dark: #1A8AA6;
    --color-teal-st: #c3e3ea;
    
    --color-ultra-violet: #bc3ebc;
    
    --color-dark-blue: #18153B;

    --color-gradient: linear-gradient(270deg, var(--color-secondary) 25%, var(--color-primary) 95%);
    --color-gradient-rot: linear-gradient(15deg, var(--color-primary) 5%, var(--color-secondary) 95%);
    --color-gradient-teal: linear-gradient(7deg, var(--color-primary) 25%, var(--color-primary-light) 95%);
    --color-gradient-purple: linear-gradient(7deg, var(--color-secondary) 35%, var(--color-secondary-light) 95%);
    --color-gradient-grey: linear-gradient(7deg, var(--color-grey-3) 24%, var(--color-grey-1) 100%);
    
    --color-link-hover: var(--color-purple-light);
    
    --font-family-content: "Work SansVariable", sans-serif;
    --font-family-header: RubikVariable, sans-serif;
    --font-family-code: "Fira Code", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
  }
  @font-face {
    font-display: swap;
  }
  
  html {
    // This defines what 1rem is: 10px; 10px/16px = 62.5%
    font-size: 16px;
    max-width: 100%;
    overflow-x: hidden;
    body{
      max-width: 100%;
      overflow-x: hidden;
    }
    
    
  }

  body {
    
    font-family: var(--font-family-content);
    font-size: 1rem;
    color: var(--color-grey-4);
    font-weight: 400;
    padding:0 !important;
    
    P{
      margin: 0.6rem 0;
    }
    p, span, ul li, ol li{
      font-size: 1rem;
      line-height:1.5rem;
      
      p, span{
        font-size:1rem;
        line-height:1.5rem;
      }
    }
    strong{
      font-weight: 500;
      color: var(--color-black);
    }
    em{
      font-variation-settings: 'ital' 1;
      font-style: italic;
      color: var(--color-purple);
    }
  }
  h1{
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.2rem;
    letter-spacing: -0.03rem;
    font-family: var(--font-family-header);
    margin:2.5rem 0 1rem 0;
    color:var(--color-black);
    @media ${devices.mobile} {
      font-size: 2.8rem;
      line-height: 3rem;
    }
  }
  h2, h3, h4, h5, h6 {
    font-weight: 700;
    color: var(--color-black);
    font-family: var(--font-family-header);
  }


  h2{    
    font-size: 2rem;
    line-height: 2.2rem;
    letter-spacing: 0;
    @media ${devices.mobile} {
      font-size: 1.9rem;
      line-height: 2.1rem;
    }
  }
  
  h3{
    font-size: 1.5rem;
    line-height: 1.75rem;
    letter-spacing: 0;
    font-weight: 600;
    margin: 0.2rem 0 1rem 0;
    &.smallSub{
      font-family: var(--font-family-content);
      font-size: 1.1875rem;
      line-height: 1.5rem;
      font-weight:400;
      margin-top: -0.5rem;
    }
  }
  h4{
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.5rem;
    margin: 0.5rem 0 1rem 0;
    
  }

  h5{
    font-size: 1.15rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.2rem;
    margin: 1rem 0 0.5rem 0;
  }

  div svg.gradient * {
    fill: url(#myGradient);
  }
  .gradientText{
    background: linear-gradient(50deg, var(--color-teal) 5%, var(--color-purple) 95%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display:inline-block;
  }
  .large{
    font-size: 1.1875rem;
    line-height: 2rem;

  }

  .error{
    color: var(--color-error);
  }

  ul,ol{
    li{
      margin-left:2rem;
      margin-bottom: 1rem;
    }
  }
  ol{
    list-style: decimal;
  }
  ul{
    list-style: disc;
  }
  blockquote, blockquote p{
    font-size: 1.1875rem;
    line-height: 2rem;
    font-style: italic;
  }

  a {
    text-decoration: none;
    transition: 0.2s;
    color: var(--color-teal-light);
    font-weight: 500;
    :hover {
      color: var(--color-link-hover);
      cursor: pointer;
      transition: color .5s;
      text-decoration: underline;
    }
    :active{
      color: var(--color-secondary-dark);
    }
    &.active {
      color: var(--color-teal) ;
    }
  }

  a.active-link {
    text-decoration: underline;
  }

  .section {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    
    &.white{
      background-color:transparent;
    }
    
    &.teal, &.purple, &.gradientTealPurple, &.gradientTeal, &.gradientPurple{
      background-color: var(--color-grey-4);
      h2{
        color: var(--color-white);
        background: transparent;
        -webkit-background-clip: none;
        background-clip: none;
        -webkit-text-fill-color: var(--color-white);
      }
    }
    &.purple, &.gradientPurple{
      background-color: var(--color-purple-dark);
      color: #9CA2AB;
      h1, h4, h3{
        color: var(--color-white);
      }
      h2{
        color: var(--color-teal-light);
        background: transparent;
        -webkit-background-clip: none;
        background-clip: none;
        -webkit-text-fill-color: var(--color-teal-light);
      }
      h4{
        color: var(--color-purple-light);
      }
      strong, em{
        color: var(--color-purple-light);
      }

    }
    &.teal, &.gradientTeal{
      background-color: var(--color-teal);
      color: var(--color-white);
      h1, h4, h3{
        color: var(--color-white);
      }
      a {
        color: var(--color-purple);
        :hover{
          color: var(--color-dark-blue);
        }
      }
      h2{
        color: var(--color-dark-blue);
        background: transparent;
        -webkit-background-clip: none;
        background-clip: none;
        -webkit-text-fill-color: var(--color-dark-blue);
      }
      h4{
        color: var(--color-purple);
      }
    }
    &.darkBlue{
      a {
        
        color: var(--color-purple-light);
        :hover{
          color: var(--color-teal);
        }
      }
      background-color: var(--color-dark-blue);
      color: #9CA2AB;
      h1, h4, h3{
        color: var(--color-white);
      }
      h2{
        color: var(--color-teal-light);
        background: transparent;
        -webkit-background-clip: none;
        background-clip: none;
        -webkit-text-fill-color: var(--color-teal-light);
      }
      h4{
        color: var(--color-teal);
      }
      strong, em{
        color: var(--color-teal-light);
      }
    }
   
    &.lightGrey{
      background-color: var(--color-grey-1);
      color: var(--color-grey-4)
    }
    &.gradientTealPurple{
      background: var(--color-gradient);
      color: var(--color-white);
      h1, h3{
        color: var(--color-white);
      }
      h2{
        color: var(--color-dark-blue);
        background: transparent;
        -webkit-background-clip: none;
        background-clip: none;
        -webkit-text-fill-color: var(--color-dark-blue);
      }
      h4{
        color: var(--color-grey-5);
      }
    }
    &.gradientLightGrey{
      background: var(--color-gradient-grey);
      color: var(--color-grey-4);
    }
    &.gradientTeal{
      background: var(--color-gradient-teal);
      color: var(--color-white);
    }
    &.gradientPurple{
      background: var(--color-gradient-purple);
      color: var(--color-white);
    }

    &.white, .lightGrey, .gradientLightGrey {
      .subline {
        background: var(--color-gradient-teal)
      }
    }

    &.teal, &.purple, &.gradientTealPurple, &.gradientTeal, &.gradientPurple, &.darkBlue {
      .subline {
        background: var(--color-gradient-grey)
      }
    }
  }

  
  .Mui-selected{
    color: var(--color-teal);
  }

  .formError{
    text-align:center;
    margin: 1rem 0;    
    color: var(--color-purple);
    font-weight:400;
    font-variation-settings: 'ital' 1;
    font-size: 1rem;
  }
  `
export default GlobalStyles
