import React from "react"
import { Link } from "gatsby"
import { NavPage } from "../../ts/interfaces"
import { makeStyles, Menu, Button, MenuProps, styled } from "@material-ui/core"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { NavItem } from "./NavItem"

interface NavMenuProps {
  title: string
  keyId: string
  items: {
    navItemPage?: NavPage
    navItemLandingPage?: NavPage
    navItemName: string
    url?: string
    onClick?: () => void
  }[]
}

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: "1rem !important",
  },
  button: {
    "&:hover": { backgroundColor: "#fff" },
  },
}))

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& a.nav-menu-link": {
      fontSize: "1rem",
      textDecoration: "none",
      padding: "10px 20px",
      // fontWeight: 500,
      letterSpacing: "1px",
      fontFamily: "var(--font-family-header)",
      color: "var(--color-grey-4)",
      "&:hover": {
        color: "var(--color-teal)",
      },
    },
    "& li.nav-menu-list": {
      marginLeft: "5px",
      display: "flex",
      marginBottom: 0,
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: "var(--color-link-hover)",
      },
      "&:hover": {
        backgroundColor: "var(--color-link-hover)",
      },
    },
  },
}))

const NavMenu = ({ title, items, keyId }: NavMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const classes = useStyles()

  return (
    <div>
      <Button
        id={`menu_toggle_${keyId}`}
        aria-controls={open ? `menu_${keyId}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disableElevation
        size="small"
        endIcon={<KeyboardArrowDownIcon />}
        classes={{ root: classes.button, label: classes.label }}
      >
        {title}
      </Button>
      <StyledMenu
        id={`menu_${keyId}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        getContentAnchorEl={null}
      >
        <div>
          {items.map(item => {
            const navItemPage = item.navItemPage
              ? item.navItemPage
              : item.navItemLandingPage

            return navItemPage?.slug ? (
              <NavItem key={navItemPage.id} scrolled={false}>
                <Link
                  id={navItemPage.id}
                  title={item.navItemName}
                  key={navItemPage.id}
                  to={navItemPage.slug ? `/${navItemPage.slug}` : ""}
                  activeClassName="active"
                  aria-label={navItemPage.title}
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                    handleClose()
                    if (navItemPage?.onClick) {
                      navItemPage.onClick(event)
                    }
                  }}
                >
                  {item.navItemName}
                </Link>
              </NavItem>
            ) : item.url ? (
              <li className="nav-menu-list">
                <a
                  className="nav-menu-link"
                  id={item.navItemName + "-id"}
                  title={item.navItemName}
                  key={item.navItemName + "-id"}
                  href={item.url}
                  aria-label={item.navItemName}
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                    handleClose()
                  }}
                >
                  {item.navItemName}
                </a>
              </li>
            ) : null
          })}
        </div>
      </StyledMenu>
    </div>
  )
}

export default NavMenu
