import React from "react"

import styled from "styled-components"
import { Link } from "gatsby"
import Container from "@material-ui/core/Container"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

import FooterNav from "../nav/FooterNav"
import Icon from "../ui/Icon"
import { devices } from "../config/breakpoints"
import { NavPage, FormZone } from "../../ts/interfaces"
import NewsletterSignup from "../forms/Newsletter"
import SimpleContent from "../sections/SimpleContent"

interface ContactListItemProps {
  id: string
  iconName: string
  iconType: "Solid" | "Brand"
  listItemText: string
  pageLink?: NavPage
}

interface SocialLinkProps {
  id: string
  url: string
  iconName: string
  iconType: "Solid" | "Brand"
}

interface TextLinkProps {
  id: string
  text: string
  url: string
}

interface FooterProps {
  data: {
    footerForm?: FormZone
    navTitle: string
    contactTitle: string
    copyrightText: string
    activeCampaignTagId: string
    activeCampaignListId: string
    contactList: ContactListItemProps[]
    textLinks: TextLinkProps[]
    socialLinks: SocialLinkProps[]
    formSnippet: string
  }
  navigation: any
  showFooter: boolean
}

const StyledLink = styled(Link)`
  color: var(--color-grey-4);
  :hover {
    color: var(--color-purple);
  }
  font-size: 16px;
  position: relative;
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down("sm")]: {
      gridItem1: {
        order: 3,
        marginTop: ".5rem",
        fontSize: "1.25rem",
        "& > p": { textAlign: "center" },
      },
      gridItem2: { order: 2 },
      gridItem3: { order: 1 },
    },
  })
)

const StyledFooter = styled.footer<{ showFooter?: boolean }>`
  padding: 40px 0;
  margin-top: 3rem;
  background-color: var(--color-white);
  border-top: ${({ showFooter }) =>
    showFooter ? "1px solid var(--color-teal)" : "none"};
  li {
    list-style: none;
    margin: 0;
  }
  z-index: 800;
  position: relative;
`

const SocialLinks = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  li {
    font-size: 1.75rem;
  }
  & a {
    color: var(--color-grey-4);
    :hover {
      color: var(--color-teal);
    }
  }

  & li:not(:last-child) {
    margin-right: 3.75rem;
  }
`

const TextLinks = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.8rem;
  & a {
    color: var(--color-grey-4);
    :hover {
      color: var(--color-purple);
    }
  }
  @media ${devices.mobile} {
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    justify-content: center;
  }
`

const ListItem = styled.li`
  display: flex;
  align-items: center;
  list-style: none;
  & span,
  & a {
    margin-left: 0.5rem;
    line-height: 1.7rem;
  }
`

const Spacer = styled.hr`
  margin-top: 2rem;
  margin-bottom: 1rem;
`

const KlaviyoForm = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Footer = ({ data, navigation, showFooter }: FooterProps) => {
  const classes = useStyles()
  const {
    footerForm,
    navTitle,
    contactTitle,
    contactList,
    copyrightText,
    textLinks,
    socialLinks,
    formSnippet,
  } = data

  return (
    <StyledFooter showFooter={showFooter}>
      {showFooter && data && (
        <Container>
          <Grid container spacing={4}>
            {/* {footerForm && (
              <Grid item xs={12} md={4}>
                <NewsletterSignup
                  form={footerForm}
                  id={"sensai-newsletter-form-footer"}
                  compress={true}
                />
              </Grid>
            )} */}
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h4>{navTitle}</h4>
                <FooterNav navigation={navigation} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={4}>
              <h4>{contactTitle}</h4>
              <ul>
                {contactList.map((contact: ContactListItemProps) => (
                  <ListItem key={contact.id}>
                    <Icon
                      size="sm"
                      iconName={contact.iconName}
                      iconType={contact.iconType}
                    />
                    {contact.pageLink ? (
                      <StyledLink
                        to={`/${contact.pageLink.slug}`}
                        title={contact.pageLink.title}
                      >
                        {contact.listItemText}
                      </StyledLink>
                    ) : (
                      <span>{contact.listItemText}</span>
                    )}
                  </ListItem>
                ))}
              </ul>
            </Grid>
            {formSnippet && (
              <Grid item xs={12} sm={12} md={5} lg={4}>
                <KlaviyoForm
                  dangerouslySetInnerHTML={{ __html: formSnippet }}
                ></KlaviyoForm>
              </Grid>
            )}
          </Grid>
          <Spacer />
          <Grid container>
            <Grid item xs={12} md={4} className={classes.gridItem1}>
              <p style={{ margin: 0 }}>
                © {new Date().getFullYear()} {copyrightText}
              </p>
            </Grid>
            <Grid item xs={12} md={4} className={classes.gridItem2}>
              <SocialLinks>
                {socialLinks.map((sl: SocialLinkProps) => (
                  <li key={sl.id}>
                    <a href={sl.url} title={sl.url}>
                      <Icon
                        size="sm"
                        iconName={sl.iconName}
                        iconType={sl.iconType}
                      />
                    </a>
                  </li>
                ))}
              </SocialLinks>
            </Grid>
            <Grid item xs={12} md={4} className={classes.gridItem3}>
              <TextLinks>
                {textLinks.map((tl: TextLinkProps) => (
                  <li key={tl.id}>
                    <a href={tl.url}>{tl.text}</a>
                  </li>
                ))}
              </TextLinks>
            </Grid>
          </Grid>
        </Container>
      )}
      {process.env.GATSBY_KLAVIYO_PUBLIC_KEY && (
        <script
          type="application/javascript"
          async
          src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.GATSBY_KLAVIYO_PUBLIC_KEY}`}
        ></script>
      )}
    </StyledFooter>
  )
}

export default Footer
