import { graphql, useStaticQuery } from "gatsby"
import { useEffect } from "react"
import { useQueryParam, NumberParam, StringParam } from "use-query-params"
import { getLs, setLs } from "../utils/localStorage"
import {
  getShareasaleAffiliateId,
  getShareasaleSSCID,
  isShareasaleAffiliate,
  storeCampaignAffiliateData,
  storeShareasaleAffiliateId,
  storeShareasaleSSCID,
} from "../utils/queryParams"

const UTM_EXPIRY_DAYS = 7

const getParams = (params: string[]) => {
  let res: any = {}
  params.forEach(p => {
    const [r] = useQueryParam(p, StringParam)
    if (r) {
      res[p] = r
    }
  })
  return res
}

const initQueryParams = () => {
  const strapiData = useStaticQuery(graphql`
    query {
      strapi {
        affiliates {
          id
          name
          code
          expiry
          duration
        }
        campaigns {
          id
          name
          code
          source
          expiry
          duration
        }
      }
    }
  `)

  const { affiliates, campaigns } = strapiData.strapi

  const {
    affiliate_id,
    sc,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
    utm_id,
    fbclid,
  } = getParams([
    "sc",
    "affiliate_id",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
    "utm_id",
    "fbclid",
  ])

  useEffect(() => {
    if (affiliate_id) {
      storeCampaignAffiliateData({
        queryParamId: affiliate_id,
        localStorageName: "affiliate",
        affiliates,
        campaigns,
      })
    }

    if (sc) {
      //campaign id
      storeCampaignAffiliateData({
        queryParamId: sc,
        localStorageName: "campaign",
        affiliates,
        campaigns,
      })
    }
    if (
      utm_source ||
      utm_medium ||
      utm_campaign ||
      utm_term ||
      utm_content ||
      utm_id
    ) {
      const date = new Date()
      setLs("utm", {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
        utm_id,
        expiry: new Date(date.setDate(date.getDate() + UTM_EXPIRY_DAYS)), //expire in 7 days
      })
    }

    if (fbclid) {
      const date = new Date()
      setLs("fbc", {
        value: "fb.1." + +new Date() + "." + fbclid,
        expiry: new Date(date.setDate(date.getDate() + UTM_EXPIRY_DAYS)), //expire in 7 days
      })
    }

    // Only replace the affiliate id with utm_campaign value if this is a shareasale transaction (and thus has an sscid)
    if (isShareasaleAffiliate() && getShareasaleAffiliateId()) {
      storeShareasaleAffiliateId(getShareasaleAffiliateId())
    }
    if (getShareasaleSSCID()) {
      storeShareasaleSSCID(getShareasaleSSCID())
    }
  }, [])
}

export default initQueryParams
