import React, { FC, useEffect } from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import { IconButton } from "./IconButton"

interface ModalProps {
  id?: string
  isOpen: boolean
  setIsOpen: Function
  title?: string
  text?: string
  children?: JSX.Element
  darkMode?: boolean
  wide?: boolean
}

const TransitionsModal: FC<ModalProps> = ({
  id,
  isOpen,
  title,
  text,
  children,
  setIsOpen,
  darkMode,
  wide,
}) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid var(--color-teal)",
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: wide ? theme.spacing(4, 2) : theme.spacing(4, 5, 4),
        position: "relative",
        maxHeight: "95vh",
        overflowY: "auto",
        overflowX: "hidden",
        "&:focus": {
          outline: "none",
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      paperDark: {
        backgroundColor: "transparent",
        border: "none",
        padding: theme.spacing(4, 5, 4),
        position: "relative",
        maxHeight: "95vh",
        overflowY: "auto",
        "&:focus": {
          outline: "none",
        },
      },

      icon: {
        position: "absolute",
        right: "0.5rem",
        top: "0.5rem",
        fontSize: "1rem",
      },
      iconDark: {
        position: "fixed",
        right: "1rem",
        top: "1rem",
        fontSize: "1rem",
      },
    })
  )

  const classes = useStyles()
  const [open, setOpen] = React.useState(isOpen)

  const handleClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen, title, text])

  return (
    <div>
      <Modal
        id={id}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: darkMode ? { backgroundColor: "rgba(0,0,0,0.9)" } : {},
        }}
      >
        <Fade in={open} timeout={{ appear: 1000, exit: 1000 }}>
          <div className={darkMode ? classes.paperDark : classes.paper}>
            <div className={darkMode ? classes.iconDark : classes.icon}>
              <IconButton
                aria-label="close"
                size="sm"
                iconColor={darkMode ? "#AAA" : "#AAA"}
                icon="times"
                onClick={handleClose}
              />
            </div>

            <h3 id="transition-modal-title">{title}</h3>
            <p id="transition-modal-description">{text}</p>
            {children}
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default TransitionsModal
