import { useStaticQuery, graphql } from "gatsby"
import {
  AffiliateFromStrapi,
  CampaignFromStrapi,
  ItemFromCms,
} from "../ts/interfaces"
import { getAffiliateCampaignData } from "./campaignAffiliateData"
import { setLs, getLs } from "./localStorage"

export const paramIsValid = (id: string, expiry: string): boolean => {
  return !!id && !!expiry && new Date() < new Date(expiry)
}

export const getParamMetaData = async (
  // strapiData: StrapiData,
  includeKeys?: string[]
): Promise<
  {
    key: string
    value: String | number
  }[]
> => {
  const strapiData = getAffiliateCampaignData()
  const metadata: { key: string; value: String | number }[] = []
  const affiliate = getLs("affiliate")
  const affiliateId = affiliate?.id
  const affiliateExpiry = affiliate?.expiry
  const utm = getLs("utm")
  const campaign = getLs("campaign")
  const campaignId = campaign?.id
  const campaignExpiry = campaign?.expiry
  const campaignSrc = campaign?.source

  if (affiliateId && paramIsValid(affiliateId, affiliateExpiry)) {
    metadata.push({ key: "affiliateId", value: affiliateId })
  } else if (getLs("userData")) {
    const { token, email } = getLs("userData")

    const billingUrl =
      process.env.GATSBY_SENSAI_SERVER_URL || "http://localhost:2002/local"

    try {
      const {
        data: { User },
      } = await (
        await fetch(`${billingUrl}/billing`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: `query userOrder($email: String!) {
            User(email: $email) {
              id
              created {
                formatted
              }
              metadata {
                id
                key
                value
              }
            }
          }`,
            variables: {
              email,
            },
          }),
        })
      ).json()

      const userAffiliate: {
        id: string
        key: string
        value: string | number
      } = User.metadata.find(
        (item: { id: string; key: string; value: string | number }) =>
          item.key === "affiliateId"
      )

      if (userAffiliate) {
        const { affiliates } = strapiData.strapi

        const strapiAffiliate = affiliates.find(
          (strapiAffiliate: AffiliateFromStrapi) =>
            strapiAffiliate.code === userAffiliate.value
        )

        // the code can be expired in two ways
        // 1. duration (calculated based on publish date + duration)
        // 2. the code expiry date

        let pushAffiliateToMetadata = false

        if (strapiAffiliate?.duration) {
          const date = new Date(strapiAffiliate.published_at)
          const expiryDate = date.setDate(
            date.getDate() + strapiAffiliate.duration
          )
          pushAffiliateToMetadata =
            new Date(User.created.formatted) < new Date(expiryDate) || false
        } else {
          pushAffiliateToMetadata =
            (strapiAffiliate &&
              new Date(User.created.formatted) <
                new Date(strapiAffiliate?.expiry)) ||
            false
        }

        if (pushAffiliateToMetadata) {
          metadata.push({ key: "affiliateId", value: userAffiliate.value })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (campaignId && paramIsValid(campaignId, campaignExpiry)) {
    metadata.push({ key: "campaignId", value: campaignId })
    if (campaignSrc) {
      metadata.push({ key: "campaignSrc", value: campaignSrc })
    }
  }
  const personaId = getLs("persona")?.id
  if (personaId) {
    metadata.push({ key: "personaId", value: personaId })
  }
  if (utm && paramIsValid("utm", utm.expiry)) {
    const vals = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
      "utm_id",
    ]
    vals.forEach(key => {
      if (utm[key]) {
        metadata.push({ key, value: utm[key] })
      }
    })
  }

  const sscid = getLs("sscid")
  const sscidId = sscid?.id
  const sscidExpiry = sscid?.expiry
  if (sscidId && paramIsValid("sscid", sscidExpiry)) {
    metadata.push({ key: "sscid", value: sscidId })
  }

  includeKeys = includeKeys || []

  includeKeys.forEach(k => {
    const value = getLs(k)?.value
    if (value !== null && value !== undefined) {
      metadata.push({ key: k, value: value })
    }
  })

  return metadata
}

export const getFbc = () => {
  const fbc = getLs("fbc")
  if (fbc && paramIsValid("fbc", fbc.expiry)) {
    return fbc.value
  }
  return ""
}

/**
 * If duration specified, expire item on lesser of duration and absolute expiry date
 * otherwise expire on absolute expiry date
 */

// this looks at the query params in url and stores it in LS with the expiry
export const storeCampaignAffiliateData = ({
  queryParamId,
  localStorageName,
  affiliates,
  campaigns,
}: {
  queryParamId: string
  localStorageName: string
  affiliates: ItemFromCms[]
  campaigns: ItemFromCms[]
}): void => {
  const affiliatesAndCampaigns: ItemFromCms[] = [...affiliates, ...campaigns]

  let itemFromCms: ItemFromCms | undefined

  if (affiliatesAndCampaigns) {
    itemFromCms = affiliatesAndCampaigns.find(
      (i: ItemFromCms) => i.code === queryParamId
    )

    if (itemFromCms && paramIsValid(itemFromCms.code, itemFromCms.expiry)) {
      const { code, expiry, duration, source } = itemFromCms
      let itemExpiresOn = new Date(expiry)

      if (duration) {
        const date = new Date()
        // add days to current -- takes care of automatically incrementing the month if required
        const expireDuration = new Date(date.setDate(date.getDate() + duration))
        itemExpiresOn =
          itemExpiresOn < expireDuration ? itemExpiresOn : expireDuration
      }

      setLs(localStorageName, { id: code, expiry: itemExpiresOn, source })
    }
  }
}

export const getFreshdeskQueryParams = () => {
  let params = { nonce: "", state: "" }

  if (window && window.location) {
    const url = new URL(window.location.href)
    const searchParams = url.searchParams
    params.nonce = searchParams.get("nonce") || ""
    params.state = searchParams.get("state") || ""
  }

  return params
}

export const getQueryParam = (key: string) => {
  let param = ""

  if (typeof window !== "undefined" && window.location) {
    const url = new URL(window.location.href)
    const searchParams = url.searchParams
    param = searchParams.get(key) || ""
  }

  return param
}

const storeQueryParam = (key: string, id: string, expiryDays: number) => {
  let expiry = new Date()
  expiry.setDate(expiry.getDate() + expiryDays)
  setLs(key, { id, expiry })
}

export const getShareasaleAffiliateId = () => getQueryParam("utm_campaign")

export const getShareasaleSSCID = () => getQueryParam("sscid")

// TODO: Confirm expiry date
export const storeShareasaleAffiliateId = (id: string) => {
  storeQueryParam("affiliate", id, 30)
}

export const storeShareasaleSSCID = (id: string) => {
  storeQueryParam("sscid", id, 30)
}

export const isShareasaleAffiliate = () => {
  return getQueryParam("utm_source").toLowerCase() === "shareasale"
}
