import React from "react"
import styled from "styled-components"

interface NavProps {
  readonly scrolled: boolean
}

const Container = styled.div`
  text-align: center;
  cursor: pointer;
`

const Icon = styled.span<NavProps>`
  position: relative;

  &,
  &::before,
  &::after {
    width: 2rem;
    height: 3px;
    border-radius: 3px;
    background-color: ${props =>
      props.scrolled ? "var(--color-grey-4)" : "var(--color-grey-3)"};
    display: block;
    background: var(--color-gradient);
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    transition: all 0.2s;
  }

  &::before {
    top: -0.6rem;
  }
  &::after {
    top: 0.6rem;
  }
`

const NavIcon = ({ scrolled }: NavProps) => (
  <Container>
    <Icon scrolled={scrolled} />
  </Container>
)

export default NavIcon
