import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import clsx from "clsx"
import { useAuthState } from "../../contexts/authContext"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import NavIcon from "./NavIcon"
import { NavItemProps, NavPage } from "../../ts/interfaces"
import { IconButton } from "../ui/IconButton"
import { Dividor } from "../ui/line"
import { NavSections, Page } from "./MainNav"
import NavButtons from "./NavButtons"
import NavMenu from "./NavMenu"
import { NavItem } from "./NavItem"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 250,
    },
    paper: {
      // height: "100%",
      height: "95vh",
      margin: "1rem 1rem 0 1rem",
      borderRadius: "10px",
      // background: "var(--color-gradient-rot)",
      background: "#fff",
      alignContent: "flex-start",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    fullList: {
      width: "100%",
      verticalAlign: "top",
      paddingTop: "4rem",
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    listItem: {
      fontFamily: "var(--font-family-header) !important",
      fontSize: "1.2rem",
      fontWeight: 700,
      textTransform: "uppercase",
      color: "var(--color-white)",
      textAlign: "center",
    },
    listItemRoot: {
      marginLeft: 0,
    },
    icon: {
      position: "absolute",
      right: "1.5rem",
      top: "1.5rem",
      fontSize: "1rem",
      zIndex: 99999,
    },
  })
)

type Anchor = "top" | "left" | "bottom" | "right"

interface DrawerProps {
  scrolled: boolean
  sections: NavSections
  navButton: any
  isOnDropdownPage: (pages: Page[]) => boolean
}

const TemporaryDrawer = ({
  scrolled,
  sections,
  navButton,
  isOnDropdownPage,
}: DrawerProps) => {
  const loginState = useAuthState()
  const loggedIn = loginState.isLoggedIn() || loginState.token

  const classes = useStyles()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  let firstSectionDone = false
  const NestedList = Object.keys(sections.groups).map(key => {
    if (key === "length") {
      return
    }
    const pages = sections.groups[key].pages

    const { navItemName, navItemPage, url } = sections.groups[key]

    if (pages.length > 1) {
      //this is a section
      firstSectionDone = true
      // return <>{pages.map(p => renderLink(p))}</>
      return (
        <NavItem
          key={navItemPage?.id + "_" + key}
          scrolled={false}
          isActive={isOnDropdownPage(pages)}
        >
          <NavMenu title={navItemName} items={pages} keyId={key} />
        </NavItem>
      )
    }
    firstSectionDone = true

    //this is a single item
    // return renderLink(sections.groups[key])
    if (navItemPage) {
      return (
        <NavItem key={navItemPage.id + "_" + key} scrolled={scrolled}>
          <Link
            to={`/${navItemPage.slug}`}
            activeClassName="active"
            id={navItemPage.id + "_" + key}
            title={navItemName}
          >
            {navItemName}
          </Link>
        </NavItem>
      )
    }
    if (url) {
      return (
        <NavItem key={navItemName + "_" + key} scrolled={scrolled}>
          <Link
            to={url}
            activeClassName="active"
            id={navItemName + "_" + key}
            title={navItemName}
          >
            {navItemName}
          </Link>
        </NavItem>
      )
    }
  })

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>{NestedList}</List>
      <NavButtons
        scrolled={false}
        flex="col"
        loggedIn={loggedIn}
        navButton={navButton}
        isMobileMenu={true}
      />
    </div>
  )

  const anchor = "top"

  return (
    <div>
      <React.Fragment key={anchor}>
        <Button
          onClick={toggleDrawer(anchor, true)}
          aria-label="Navigation"
          title="Navigation"
        >
          <NavIcon scrolled={scrolled} />
        </Button>
        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          classes={{
            paper: classes.paper,
          }}
        >
          <div className={classes.icon}>
            <IconButton
              aria-label="close"
              size="sm"
              iconColor="var(--color-teal)"
              icon="times"
              onClick={toggleDrawer(anchor, false)}
            />
          </div>
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  )
}

export default TemporaryDrawer
