import React, { useEffect } from "react"
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent"
import { useStaticQuery, graphql, Link } from "gatsby"
import Markdown from "markdown-to-jsx"
import Cookies from "js-cookie"
import { initializeAndTrack } from "../../plugins/gatsby-plugin-gdpr-cookies-sensai"
import { isInEU } from "../utils/format"
import { getParamMetaData } from "../utils/queryParams"
import { useCheckoutState } from "../checkout/state"

declare global {
  interface Window {
    fbq: any
    gtag: any
    _learnq: any
    trackingInit: boolean
    gatsbyPluginGDPRCookiesOptions: any
  }
}

const COOKIE_NAME = "CookieConsent-s"

const CookiePolicy = () => {
  const state = useCheckoutState()

  const data = useStaticQuery(graphql`
    query {
      strapi {
        siteConfig {
          cookieBanner
        }
      }
    }
  `)

  const initTracking = () => {
    if (window.trackingInit || !window.gatsbyPluginGDPRCookiesOptions) {
      return
    }
    window.trackingInit = true
    if (typeof location !== `undefined`) {
      initializeAndTrack(location, true, async () => {
        if (typeof window !== `undefined` && window.gtag) {
          const meta = await getParamMetaData()
          const source = meta.find(m => m.key === "utm_source")
          const campaign = meta.find(m => m.key === "utm_campaign")
          const medium = meta.find(m => m.key === "utm_medium")
          const term = meta.find(m => m.key === "utm_term")
          const content = meta.find(m => m.key === "utm_content")
          const utmId = meta.find(m => m.key === "utm_id")

          await window.gtag("set", {
            ...(source && { campaign_source: source.value }),
            ...(medium && { campaign_medium: medium.value }),
            ...(campaign && { campaign_name: campaign.value }),
            ...(term && { campaign_term: term.value }),
            ...(content && { campaign_content: content.value }),
            ...(utmId && { campaign_id: utmId.value }),
          })
        }
      })
    }
  }

  useEffect(() => {
    if (getCookieConsentValue(COOKIE_NAME)) {
      initTracking()
    }
  }, [])

  if (getCookieConsentValue(COOKIE_NAME)) {
    return <></>
  }

  if (
    state.country &&
    !isInEU(state.country) &&
    !getCookieConsentValue(COOKIE_NAME)
  ) {
    Cookies.set(COOKIE_NAME, "true", { secure: true, sameSite: "strict" })
    initTracking()

    return <></>
  }

  return data.strapi.siteConfig && data.strapi.siteConfig.cookieBanner ? (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName={COOKIE_NAME}
      style={{ alignItems: "center", fontSize: "1.25rem" }}
      buttonStyle={{ backgroundColor: "var(--color-purple)", color: "white" }}
      enableDeclineButton
      declineButtonText="Decline"
      declineButtonStyle={{
        backgroundColor: "var(--color-grey-3)",
        color: "white",
      }}
      onAccept={() => {
        initTracking()
      }}
    >
      <p>
        {data.strapi.siteConfig && data.strapi.siteConfig.cookieBanner && (
          <Markdown>{data.strapi.siteConfig.cookieBanner}</Markdown>
        )}{" "}
        Please refer to our privacy policy{" "}
        <Link to="/privacy-policy">here</Link>.
      </p>
    </CookieConsent>
  ) : null
}

export default CookiePolicy
