import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import AOS from "aos"
import { StylesProvider } from "@material-ui/core/styles"
import StickyBanner from "./SickyBanner"
import { library } from "@fortawesome/fontawesome-svg-core"
import styled from "styled-components"

import Header from "./Header"
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
  faCrutch,
  faAssistiveListeningSystems,
  faRocket,
  faBrain,
  faShoppingCart,
  faCaretDown,
  faUser,
  faPlus,
  faMinus,
  faCreditCard,
  faBox,
  faTrash,
  faCoffee,
  faProjectDiagram,
  faHeartbeat,
  faChargingStation,
  faChartLine,
  faHeadphones,
  faTools,
  faPallet,
  faDraftingCompass,
  faWrench,
  faAngleDoubleDown,
  faCompressArrowsAlt,
  faCheck,
  faPaperPlane,
  faFileUpload,
  faKey,
  faQuoteLeft,
  faUserCircle,
  faCheckCircle,
  faUserLock,
  faLock,
  faArrowCircleDown,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faUserPlus,
  faExclamationTriangle,
  faAngleDoubleUp,
  faChevronUp,
  faChevronDown,
  faClock,
  faStopwatch,
  faPlay,
  faArrowRight,
  faArrowLeft,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons"

library.add(
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
  faCrutch,
  faAssistiveListeningSystems,
  faRocket,
  faBrain,
  faShoppingCart,
  faCaretDown,
  faUser,
  faPlus,
  faCreditCard,
  faMinus,
  faBox,
  faTrash,
  faCoffee,
  faProjectDiagram,
  faHeartbeat,
  faChargingStation,
  faChartLine,
  faHeadphones,
  faTools,
  faPallet,
  faDraftingCompass,
  faWrench,
  faAngleDoubleDown,
  faCompressArrowsAlt,
  faCheck,
  faPaperPlane,
  faFileUpload,
  faKey,
  faQuoteLeft,
  faUserCircle,
  faCheckCircle,
  faUserLock,
  faLock,
  faArrowCircleDown,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faUserPlus,
  faExclamationTriangle,
  faAngleDoubleUp,
  faChevronUp,
  faChevronDown,
  faClock,
  faStopwatch,
  faPlay,
  faArrowRight,
  faArrowLeft,
  faQuestion
)

import Footer from "./footer/Footer"
import "./styles/reset.css"
import "aos/dist/aos.css"
import GlobalStyles from "./styles/global"
import CookiePolicy from "./CookiePolicy"
import initQueryParams from "../utils/setQueryParams"
import "@fontsource/rubik/400.css"
import "@fontsource/rubik/variable.css"
import "@fontsource/rubik/variable-italic.css"
import "@fontsource/work-sans/400.css"
import "@fontsource/work-sans/variable.css"
import "@fontsource/work-sans/variable-italic.css"
import { config, dom } from "@fortawesome/fontawesome-svg-core"
import { useCheckoutDispatch } from "../checkout/state"
import { CheckoutActionKind } from "../checkout/types/checkoutTypes"
import { setLs } from "../utils/localStorage"
config.autoAddCss = false

let lastPos: number
const scrollTransitionDist = 150

const FullHeight = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

const Layout = ({
  children,
  hasHero = false,
  showStickFooter = false,
  showFooter = true,
  showHeader = true,
}) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    })
    AOS.refresh()
  }, [])

  const [scrolled, setScrolled] = useState(false)

  const listenScrollEvent = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop

    if (lastPos === null || lastPos === undefined || distanceY != lastPos) {
      lastPos = distanceY

      const scrolledUpdated = distanceY > scrollTransitionDist
      if (scrolledUpdated !== scrolled) {
        setScrolled(scrolledUpdated)
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent, { passive: true })
    return () => window.removeEventListener("scroll", listenScrollEvent)
  })

  const checkoutDispatch = useCheckoutDispatch()

  useEffect(() => {
    if (
      typeof window !== `undefined` &&
      (!window.localStorage.getItem("currency") ||
        !window.localStorage.getItem("country_for_inventory") ||
        !window.localStorage.getItem("country_code"))
    ) {
      let url = process.env.GATSBY_SENSAI_SERVER_URL
      if (!url || url === "localhost") {
        url = "http://localhost:5000/local"
      }

      fetch(`${url}/country/`)
        .then(async r => r.json())
        .then(
          (res: {
            currency: string
            country_code_iso3: string
            country_name: string
          }) => {
            const curr = res.currency === "CAD" ? "cad" : "usd"

            setLs("country_code", res.country_code_iso3)
            setLs("currency", curr)
            setLs("country_for_inventory", res.country_name)

            checkoutDispatch({
              type: CheckoutActionKind.SET_COUNTRY,
              payload: { country: res.country_code_iso3 },
            })
            checkoutDispatch({
              type: CheckoutActionKind.SET_CURRENCY,
              payload: { currency: curr },
            })
            checkoutDispatch({
              type: CheckoutActionKind.SET_COUNTRY_INVENTORY,
              payload: { country: res.country_name },
            })
          }
        )
        .catch(() => {}) // just keep USD as curr and ignore.
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
      strapi {
        footer {
          formSnippet
          footerForm {
            type
            formTitle
            buttonText
            buttonType
            successMessage
            emailSignupNameOptions
            successRedirect {
              navItemPage {
                id
                title
                slug
              }
              navItemLandingPage {
                id
                title
                slug
              }
            }
          }
          navTitle
          contactTitle
          activeCampaignTagId
          activeCampaignListId
          contactList {
            id
            iconType
            iconName
            listItemText
            pageLink {
              id
              title
              slug
            }
          }
          copyrightText
          textLinks {
            id
            text
            url
          }
          socialLinks {
            id
            iconType
            iconName
            url
          }
        }
        navigations {
          id
          location
          pages {
            __typename
            ... on STRAPI_ComponentNavigationMainNav {
              navItemPage {
                id
                title
                slug
              }
              navItemName
              sectionGroup
            }
            ... on STRAPI_ComponentNavigationHyperlink {
              url
              navItemName
            }
          }
          button {
            id
            title
            type
            link {
              __typename
              ... on STRAPI_ComponentNavigationMainNav {
                navItemName
                navItemPage {
                  id
                  slug
                }
                navItemLandingPage {
                  id
                  slug
                }
              }
              ... on STRAPI_ComponentNavigationProduct {
                id
                addToCart
                linkText
                product {
                  id
                  stripeId
                  stripeTestId
                  slug
                }
              }
              ... on STRAPI_ComponentNavigationHyperlink {
                url
              }
              ... on STRAPI_ComponentZonesQuiz {
                id
                quizId
              }
            }
          }
        }
      }
    }
  `)
  const footerNavigation = data.strapi.navigations.find(
    nav => nav.location === "footer"
  )
  initQueryParams()

  return (
    <StylesProvider injectFirst>
      <GlobalStyles />
      <style jsx global>{`
        ${dom.css()}
      `}</style>
      <FullHeight>
        <div
          style={{
            margin: `0 auto`,
            zIndex: 1,
            width: "100%",
          }}
        >
          <main>
            <>
              {showHeader && (
                <Header id="js-header" hasHero={hasHero} scrolled={true} />
              )}
              {children}
            </>
          </main>

          <svg width="0" height="0">
            <linearGradient id="myGradient" x1="0%" y1="50%" x2="100%" y2="0%">
              <stop stopColor="var(--color-purple)" offset="0.2" />
              <stop stopColor="var(--color-teal)" offset="0.7" />
            </linearGradient>
          </svg>
          <CookiePolicy />
        </div>

        {showStickFooter && footerNavigation && footerNavigation.button && (
          <StickyBanner
            button={{ ...footerNavigation.button }}
            scrolled={scrolled}
          />
        )}
        <Footer
          data={data?.strapi?.footer}
          navigation={footerNavigation}
          showFooter={showFooter}
        />
      </FullHeight>
    </StylesProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
