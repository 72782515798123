import styled from "styled-components"
import { devices } from "../config/breakpoints"

interface NavProps {
  readonly scrolled: boolean
  hideMobile?: boolean
  hideDesktop?: boolean
  isActive?: boolean
}

export const NavItem = styled.li<NavProps>`
  display: flex;
  ${props => (props.hideDesktop ? "display: none;" : "")};

  ${props =>
    props.hideMobile
      ? ` @media ${devices.mobile} {
    display: none;
  }`
      : ""};

  align-items: center;
  margin-left: 5px;
  margin-bottom: 0;

  a,
  button {
    font-size: 1rem;
    text-transform: none;
    & span {
      font-size: 0.9rem;
    }

    padding: 10px 20px;
    color: ${props =>
      props.isActive ? "var(--color-teal)" : "var(--color-grey-4)"};
    font-family: var(--font-family-header);

    letter-spacing: 1px;
    transition: color 0.5s ease;
    :hover {
      text-decoration: none;
      color: ${props =>
        props.scrolled ? "var(--color-teal)" : "var(--color-teal)"};
    }
  }
`
