import API from "../server/serverInterface"
import { navigate } from "gatsby"
import { useAuthState, AuthStateType } from "../contexts/authContext"
import { ApolloError, useLazyQuery, QueryLazyOptions } from "@apollo/client"
import { CheckErrors } from "../utils/grapqhlErrorCheck"
import { useEffect, useState } from "react"

interface hookObjectRes {
  loading: boolean
  error?: ApolloError
  data?: any
  errMsg?: string
  loadingAuth: boolean
}
declare type QueryTuple = [
  (options?: QueryLazyOptions<Record<string, any>>) => void,
  hookObjectRes
]

export const useLazyApi = (
  clientName: string,
  apiDef: string,
  includeAuthEmailAs?: string
): QueryTuple => {
  const authState = useAuthState() as AuthStateType
  const [loadingAuth, setLoadingAuth] = useState(true)

  const [errMsg, setErrorMsg] = useState("")
  const [err, setError] = useState(undefined)
  const [preAuthCalled, setPreAuthCalled] = useState(false)
  const [exParams, setParams] = useState<Record<string, any> | undefined>(
    undefined
  )

  const ex = async (params: Record<string, any>): Promise<void> => {
    // this needs to auto call once we are ready

    if (loadingAuth) {
      setParams(params)
      setPreAuthCalled(true)
    } else {
      return runQuery(params)
    }
  }

  const client = API.getClientDef(clientName)
  if (!client) {
    throw Error("Client not found")
  }
  const queryDef = client.getDef(apiDef)
  if (!queryDef) {
    throw Error("Api not found")
  }
  if (queryDef.type !== "query") {
    throw Error("Api is not a query")
  }

  let [execute, queryResult] = useLazyQuery(queryDef.gql, {
    client: client.client,
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    if (setErrorMsg) {
      setErrorMsg(CheckErrors(queryResult?.error, queryDef.authRequired) || "")
    }
  }, [queryResult])

  const runQuery = async (params?: Record<string, any>) => {
    if (
      includeAuthEmailAs &&
      authState.isLoggedIn() &&
      params &&
      params.variables
    ) {
      params.variables[includeAuthEmailAs] = authState.email?.toLowerCase()
    }
    try {
      await execute(params)
    } catch (error) {
      setErrorMsg("There was a problem executing your request")
      setError(error)
    }
  }

  useEffect(() => {
    if (
      !queryDef.authRequired ||
      (authState.localStorageLoaded && authState.isLoggedIn())
    ) {
      if (preAuthCalled) {
        // don't want to do this again
        setPreAuthCalled(false)
        runQuery(exParams)
      }

      setLoadingAuth(false)
    }

    // if auth is not required but we don't explicity set it, it defaults to true
    // if the server does not require auth then the request gets swallowed
    // this acts as a warning in case the network request doesn't go through

    if (
      process.env.GATSBY_STRIPE_ENVIRONMENT !== "production" &&
      authState.localStorageLoaded &&
      !authState.isLoggedIn()
    ) {
      console.warn(
        "this query is set to require authentication. if the query should not require authentication, please set that in the apiDef."
      )
    }
  }, [authState, exParams, preAuthCalled])

  return [
    ex,
    {
      ...queryResult,
      loading: loadingAuth || queryResult.loading,
      error: queryResult.error || err,
      errMsg,
      loadingAuth,
    },
  ]
}
