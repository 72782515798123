import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Grid, Hidden } from "@material-ui/core"
import { NavItemProps } from "../../ts/interfaces"
import { Button } from "../ui/Button"

const ListItems = styled.div`
  margin-top: 0.75rem;
`

const StickyBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-color: var(--color-white);
  z-index: 10;
`

const StyledLink = styled(Link)`
  color: var(--color-grey-4);
  :hover {
    color: var(--color-purple);
  }
  font-size: 16px;
  position: relative;
`

const StyledAnchor = styled.a`
  color: var(--color-grey-4);
  :hover {
    color: var(--color-purple);
  }
  font-size: 16px;
  position: relative;
`

const FooterNav = ({ navigation }: { navigation: any }) => {
  return (
    <>
      {/* {navigation.button && (
        <StickyBanner>
          <Button
            {...navigation.button}
            size="control"
            sectionId="header_nav"
          />
        </StickyBanner>
      )} */}
      <ListItems>
        <Grid container spacing={4}>
          {navigation &&
            navigation.pages &&
            navigation.pages.map(
              ({ navItemName, navItemPage, url }: NavItemProps) => {
                if (navItemPage) {
                  return (
                    navItemPage &&
                    navItemName && (
                      <Grid
                        key={navItemPage.id}
                        item
                        xs={6}
                        style={{ padding: "5px 16px" }}
                      >
                        <StyledLink
                          to={`/${navItemPage.slug}`}
                          title={navItemPage.title}
                        >
                          {navItemName}
                        </StyledLink>
                      </Grid>
                    )
                  )
                }
                return (
                  url &&
                  navItemName && (
                    <Grid
                      key={`footerlink-${navItemName}`}
                      item
                      xs={6}
                      style={{ padding: "5px 16px" }}
                    >
                      <StyledAnchor href={`${url}`} title={navItemName}>
                        {navItemName}
                      </StyledAnchor>
                    </Grid>
                  )
                )
              }
            )}
        </Grid>
      </ListItems>
    </>
  )
}

export default FooterNav
