import Modal from "./Modal"
import React, { useState } from "react"
import styled from "styled-components"
import { ButtonProps } from "../../ts/interfaces"
import { LinkBtnBase } from "./Button"
import { devices } from "../config/breakpoints"
import PaddedContainer from "./PaddedContainer"

type QuizButtonProps = ButtonProps & {
  baseSpan: HTMLSpanElement
  link: {
    id: string
    quizId: string
  }[]
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 90vh;
  width: 90vw;
  @media ${devices.mobile} {
    height: 90vh;
    width: 90vw;
  }
`

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Quiz = (props: QuizButtonProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <PaddedContainer>
      <LinkBtnBase
        id={props.id}
        aria-label={props.title}
        onClick={props.disabled !== true ? () => setModalOpen(true) : null}
        btntype={props.type}
        size={props.size}
        disabled={props.disabled || false}
        className={`btn ${props.className}`}
      >
        {props.baseSpan}
      </LinkBtnBase>
      <Modal setIsOpen={setModalOpen} isOpen={modalOpen} darkMode={true}>
        <Wrapper>
          <script src="https://lq3-production01.s3.amazonaws.com/lead_quizzes_3.0/tracking/js/properties/ea1e41gdklrm7g.js"></script>
          <div id="inline-embed-iframe" className="inline-embed-responsive">
            <IFrame
              src={`https://content.leadquizzes.com/lp/${props.link[0].quizId}?embed=1`}
              frameBorder="0"
            ></IFrame>
          </div>
        </Wrapper>
      </Modal>
    </PaddedContainer>
  )
}

export default Quiz
