/**
 * Icon List: https://fontawesome.com/cheatsheet/free/brands
 */

import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SensaiColor } from "../../ts/interfaces"

export type SizeProp =
  | "xs"
  | "lg"
  | "sm"
  | "1x"
  | "2x"
  | "3x"
  | "4x"
  | "5x"
  | "6x"
  | "7x"
  | "8x"
  | "9x"
  | "10x"

export type iconType = "Solid" | "Brand"

interface IconProps {
  iconName: string
  iconType: iconType
  iconColor?: string
  size?: SizeProp
  backgroundColor?: SensaiColor
  brightColor?: boolean
}

const getIconTypeStr = (type: iconType): string => {
  switch (type) {
    case "Brand":
      return "fab"
    case "Solid":
    default:
      return "fas"
  }
}

const getIconNameStr = (name: string): string => {
  switch (name) {
    case "mapmarkeralt":
      return "map-marker-alt"
    case "facebookf":
      return "facebook-f"
    case "linkedinin":
      return "linkedin-in"
    case "assistivelisteningsystems":
      return "assistive-listening-systems"
    case "projectdiagram":
      return "project-diagram"
    case "chargingstation":
      return "charging-station"
    case "chartline":
      return "chart-line"
    case "draftingcompass":
      return "drafting-compass"
    case "compressarrowsalt":
      return "compress-arrows-alt"
    default:
      return name
  }
}

const Icon = ({
  iconName,
  iconType,
  iconColor,
  size,
  backgroundColor,
  brightColor,
}: IconProps) => {
  let gradient = false

  if (!iconColor && backgroundColor) {
    switch (backgroundColor) {
      case "teal":
      case "gradientTealPurple":
      case "gradientPurple":
      case "gradientTeal":
        iconColor = brightColor
          ? "var(--color-teal-light)"
          : "var(--color-white)"
        break
      case "purple":
      case "darkBlue":
        gradient = true
        iconColor = "var(--color-white)"
        break
      case "white":
      case "lightGrey":
      case "gradientLightGrey":
      default:
        iconColor = "var(--color-grey-4)"
        gradient = brightColor ? true : false
        break
    }
  }
  return (
    <FontAwesomeIcon
      icon={[getIconTypeStr(iconType), getIconNameStr(iconName)]}
      color={iconColor}
      size={size}
      className={gradient ? "gradient" : ""}
    />
  )
}

export default Icon
