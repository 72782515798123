type Behavior = "Added to Cart" | "Viewed Product" | "Started Checkout"

export const klaviyoIdentify = (
  email: string,
  firstName?: string,
  lastName?: string,
  allowMarketing?: boolean
) => {
  if (
    typeof window !== `undefined` &&
    getCookie("CookieConsent-s") === "true"
  ) {
    const _learnq = window._learnq || []
    _learnq.push([
      "identify",
      {
        $email: email,
        $first_name: firstName ? firstName : undefined,
        $last_name: lastName ? lastName : undefined,
        allow_marketing: allowMarketing ? "true" : undefined,
      },
    ])
  }
}

export const klaviyoTrackBehavior = (name: Behavior, item: Object) => {
  if (getCookie("CookieConsent-s") === "true") {
    const _learnq = window._learnq || []
    _learnq.push(["track", name, item])
  }
}

export const getCookie = (name: string) => {
  var nameEQ = name + "="
  var ca = document.cookie.split(";")
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == " ") c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}
