import { useAuthDispatch } from "../contexts/authContext"
import { useApiMutation } from "../hooks/useApiMutation"
import { useEffect } from "react"

export const useLogout = (redirectUrl?: string) => {
  const dispatch = useAuthDispatch()

  const [executeMutation, { loading, error, data }] = useApiMutation(
    "account",
    "logout"
  )
  const loggedOut = () => {
    dispatch({ type: "LOGOUT", redirectUrl })
  }

  useEffect(() => {
    if (data || error) {
      loggedOut()
    }
  }, [data, error])

  const logout = () => {
    try {
      executeMutation()
    } catch (error) {
      loggedOut()
    }
  }

  return logout
}
