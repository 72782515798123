import styled from "styled-components"
import React from "react"
import { ButtonProps } from "../ts/interfaces"
import { Button } from "./ui/Button"
import PaddedContainer from "./ui/PaddedContainer"

const StickyBannerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  background-color: var(--color-white);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  z-index: 700;
`

const StickyBannerContent = styled.div<{ scrolled: boolean }>`
  display: flex;
  vertical-align: center;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  transition: height 1s ease;
  height: ${({ scrolled }) => (scrolled ? "4rem" : "0rem")};
  font-weight: 600;
  overflow: hidden;
`

const SickyBanner = ({
  button,
  scrolled,
}: {
  button: ButtonProps
  scrolled: boolean
}) => {
  return (
    <StickyBannerContainer>
      <PaddedContainer padding="0 1rem">
        <StickyBannerContent scrolled={scrolled}>
          <p>{button.title}</p>
          <Button {...button} size="control" sectionId="sticky_footer_nav" />
        </StickyBannerContent>
      </PaddedContainer>
    </StickyBannerContainer>
  )
}

export default SickyBanner
