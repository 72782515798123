import React from "react"

import { ButtonProps } from "../../ts/interfaces"
import { getCartProduct, getStripeId } from "../../utils/stripejs"
import { useLazyApi } from "../../hooks/useLazyApi"
import { addToCart as addItemToCart } from "../../checkout/events"
import { useCheckoutState, useCheckoutDispatch } from "../../checkout/state"

const CartButton = (props: ButtonProps) => {
  const { quantity } = props

  const checkoutState = useCheckoutState()
  const dispatch = useCheckoutDispatch()

  const [sendPxEvent] = useLazyApi("website", "sendPixelEvent")

  if (!props.link) {
    return <div>PRODUCT NOT FOUND</div>
  }

  const { product, addToCart } = props.link[0]

  const stripeId = getStripeId(product?.stripeId, product?.stripeTestId)
  const cartProduct = product ? getCartProduct(stripeId) : null

  if (cartProduct === null || cartProduct === undefined || !product) {
    return <div>PRODUCT NOT FOUND</div>
  }

  const handleClick = () => {
    if (!addToCart) return

    addItemToCart(
      checkoutState,
      dispatch,
      quantity || 1,
      product?.stripeId,
      product?.stripeTestId,
      sendPxEvent
    )
  }

  return <span onClick={handleClick}>{props.children}</span>
}

export default CartButton
