import { Dispatch } from "react"

import { setLs } from "../../utils/localStorage"
import { Action, CheckoutActionKind, State } from "../types/checkoutTypes"

export const incrementCartItem = (
  cartItemId: string,
  state: State,
  dispatch: Dispatch<Action>
) => {
  // Update global state
  dispatch({
    type: CheckoutActionKind.INCREMENT_CART_ITEM,
    payload: { cartItemId },
  })

  // Persist to localstorage
  const updatedCartItems = state.cartItems.map(item => {
    if (item.productId === cartItemId) {
      const newQuantitiy = item.quantity + 1
      return { productId: item.productId, quantity: newQuantitiy }
    } else {
      return item
    }
  })
  setLs("cartItems", updatedCartItems)
}
