import React from "react"
import { Link as GatsbyLink } from "gatsby"
// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  to = to ? to : other.href
  to = to ? to : other.hyperlink

  const isHttp = to && (to as String).startsWith("http")
  const isSensai = to && (to as String).startsWith("https://sens.ai")
  const isSensaiSupport = to && (to as String).includes("sens.ai/support")

  const internal =
    to && !isSensaiSupport && (isSensai || !isHttp || /^\/(?!\/)/.test(to))
  // Use Gatsby Link for internal links, and <a> for others

  if (internal) {
    to = isSensai || to.indexOf("/") === 0 ? to : `/${to}`
    to = to === "/" || to.endsWith("/") ? to : `${to}/`

    return (
      <GatsbyLink
        key={"link" + (to.indexOf("/") === 0 ? to : `/${to}`)}
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a href={to} target="_blank" rel="noopener noreferrer" {...other}>
      {children}
    </a>
  )
}
export default Link
