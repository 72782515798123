import React from "react"
import styled from "styled-components"
import { Hidden } from "@material-ui/core"

import { NavItem } from "./NavItem"
import { Button } from "../ui/Button"

interface Props {
  flex: string | undefined
}

const Container = styled.ul<Props>`
  display: flex;
  flex-direction: ${({ flex }) => (flex === "col" ? "column" : "row")};
  align-items: center;
  width: ${({ flex }) => (flex === "col" ? "100%" : "")};

  & > li {
    width: ${({ flex }) => (flex === "col" ? "100%" : "")};
    margin-left: 0;
  }
  & > li > a,
  & li > a > span,
  & li > a > span > span,
  & li > a > span > span > span {
    width: ${({ flex }) => (flex === "col" ? "100%" : "")};
    text-align: ${({ flex }) => (flex === "col" ? "center" : "")};
  }

  & li > a > span > span:first-of-type.outterBtn {
    :hover {
      background-color: var(--color-teal) !important;
    }
  }
`

const NavButtons = ({
  scrolled,
  hideMobile,
  flex,
  loggedIn,
  navButton,
  isMobileMenu,
}: {
  scrolled: boolean
  hideMobile?: boolean
  flex?: string
  loggedIn?: boolean
  navButton?: any
  isMobileMenu?: boolean
}) => {
  return (
    <Hidden xsDown={!isMobileMenu}>
      <div style={{ marginLeft: 0, marginBottom: 0 }}>
        <Container flex={flex}>
          {navButton && (
            <NavItem scrolled={scrolled} hideMobile={hideMobile}>
              <Button {...navButton} size="control" />
            </NavItem>
          )}
          {!loggedIn && (
            <NavItem scrolled={scrolled} hideMobile={hideMobile}>
              <Button
                type="secondaryOutline"
                id="nav-login-button"
                data-test-id="main-nav-login"
                sectionId=""
                title="Login"
                link={[{ url: "/login" }]}
                size="control"
              />
            </NavItem>
          )}
        </Container>
      </div>
    </Hidden>
  )
}

export default NavButtons
