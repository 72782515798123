import { useStaticQuery, graphql } from "gatsby"

let data: any
export const getAffiliateCampaignData = () => {
  return data || {}
}

export const LoadCampaignAffiliateData = () => {
  if (data) {
    return
  }
  data = useStaticQuery(graphql`
    query {
      strapi {
        affiliates {
          id
          name
          code
          expiry
          duration
        }
        campaigns {
          id
          name
          code
          source
          expiry
          duration
        }
      }
    }
  `)
}
