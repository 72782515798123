import React from "react"
import styled from "styled-components"
import { ButtonProps, ButtonType, ButtonLink } from "../../ts/interfaces"
import CartButton from "../cart/CartButton"
import Link from "./link"
import Icon from "./Icon"
import { devices } from "../config/breakpoints"
import Quiz from "./Quiz"

interface BtnStyleProps {
  btntype: ButtonType
  disabled: boolean
  size?: "hero" | "regular" | "control"
}
interface BtnStyleDef {
  base: string
  hover: string
  active: string
}
const btnDefs: Record<ButtonType, BtnStyleDef> = {
  text: {
    base: `
      .innerBtn{
        transition: all 0.75s ease;
        color: var(--color-grey-3) !important;
        a {
          transition: all 0.75s ease;
          color: var(--color-grey-3) !important;
        }
      }
    `,
    hover: `
    .innerBtn{
      color: var(--color-grey-4) !important;
      a {
        color: var(--color-grey-4) !important;
      }
    }
    `,
    active: `
    .innerBtn{
      color: var(--color-grey-3) !important;
      a {
        color: var(--color-grey-3) !important;
      }
    }
    `,
  },
  textSecondary: {
    base: `
      .innerBtn{
        transition: all 0.75s ease;
        color: var(--color-secondary) !important;
        a {
          transition: all 0.75s ease;
          color: var(--color-secondary) !important;
        }
      }
    `,
    hover: `
    .innerBtn{
      color: var(--color-secondary-light) !important;
      a {
        color: var(--color-secondary-light) !important;
      }
    }
    `,
    active: `
    .innerBtn{
      color: var(--color-secondary-dark) !important;
      a {
        color: var(--color-secondary-dark) !important;
      }
    }
    `,
  },
  primary: {
    base: `
      .outterBtn{
        background: linear-gradient(270deg, var(--color-secondary) 2%, var(--color-primary) 98%);
        .innerBtn{
          color: var(--color-white) !important;
        }
      }
      a {
        color: var(--color-white) !important;
      }
    `,
    hover: `
      .outterBtn{
        background: linear-gradient(270deg, var(--color-secondary) 2%, var(--color-secondary) 98%);
      }
    `,
    active: `
      .outterBtn{
        background: linear-gradient(270deg, var(--color-secondary-light) 2%, var(--color-secondary-light) 98%);
      }
      
    `,
  },
  primaryOutline: {
    base: `
      position: relative;
      .outterBtn{
        background: linear-gradient(270deg, var(--color-secondary) 2%, var(--color-primary) 98%);
        .innerBtn{
          background: var(--color-white) !important;
          color: var(--color-primary) !important;
        }
      }
      
      a {
        color: var(--color-primary) !important;
      }
    
    `,
    hover: `
      a {
        color: var(--color-secondary) !important;
      }
      .outterBtn{
        background: linear-gradient(270deg, var(--color-secondary-light) 2%, var(--color-secondary-light) 98%);
        .innerBtn{
          color: var(--color-secondary) !important;
        }
      }
    `,
    active: `
      color: var(--color-primary) !important;
      a {
        color: var(--color-primary) !important;
      }
      .outterBtn{
        .innerBtn{
          color: var(--color-primary) !important;
        }
      }
    `,
  },
  primaryTab: {
    base: `
      .outterBtn{
        padding: 1px 1px 3px 1px;
        border-radius: 2rem 2rem 0 0;
        background: var(--color-white) ;
        .innerBtn{
          border-radius: 2rem 2rem 0 0;
          background: var(--color-white) !important;
          color: var(--color-primary) !important;
        }
      }
      
      a {
        color: var(--color-primary) !important;
      }
      
      
    `,
    hover: `
      
      a {
        color: var(--color-secondary) !important;
      }
      .outterBtn{
        background: linear-gradient(270deg, var(--color-secondary) 2%, var(--color-primary) 98%);
        .innerBtn{
          color: var(--color-secondary) !important;
        }
      }
    `,
    active: `
      
      a {
        color: var(--color-primary) !important;
      }
      .outterBtn{
        .innerBtn{
          color: var(--color-primary) !important;
        }
      }
    `,
  },
  primaryTabActive: {
    base: `
    .outterBtn{
        padding: 1px 1px 3px 1px;
        border-radius: 2rem 2rem 0 0;
        background: var(--color-secondary-light) !important;
        .innerBtn{
          border-radius: 2rem 2rem 0 0;
          background: var(--color-white) !important;
        }
      }
      color: var(--color-secondary) !important;
      a {
        color: var(--color-secondary) !important;
      }
      
    `,
    hover: `
      color: var(--color-primary) !important;
      a {
        color: var(--color-primary) !important;
      }
      .outterBtn{
        background: var(--color-secondary-light) !important;
      }
    `,
    active: `
      color: var(--color-secondary-dark) !important;
      a {
        color: var(--color-secondary-dark) !important;
      }
    `,
  },
  secondary: {
    base: `
      text-transform: uppercase;
      .outterBtn{
        background: var(--color-secondary) !important;
        .innerBtn{
          color: var(--color-white) !important;
        }
      }
      
      a {
        color: var(--color-white) !important;
      }
    `,
    hover: `
    .outterBtn{
      background: var(--color-secondary-light) !important;
    }
    `,
    active: `
    .outterBtn{
        background: var(--color-secondary-dark) !important;
    }
    `,
  },
  secondaryOutline: {
    base: `
      position: relative;
      .outterBtn{
        background: var(--color-secondary) !important;
        .innerBtn{
          background: var(--color-white) !important;
          color: var(--color-secondary) !important;
        }
      }
      
      a {
        color: var(--color-secondary) !important;
      }
    
      
    `,
    hover: `
      
      a {
        color: var(--color-primary) !important;
      }
      .outterBtn{
        background: var(--color-primary-light) !important;
        .innerBtn{
          color: var(--color-primary) !important;
        }
      }
    `,
    active: `
      
      a {
        color: var(--color-primary) ;
      }
      .outterBtn{
        .innerBtn{
          color: var(--color-primary) !important;
        }
      }
    `,
  },
  alternate: {
    base: `
      .outterBtn{
        background: var(--color-grey-4) !important;
        .innerBtn{
          color: var(--color-white) !important;
        }
      }
      
      a {
        color: var(--color-white) !important;
      }
    `,
    hover: `
      .outterBtn{
        background: var(--color-grey-3) !important;
      }
    `,
    active: `
    .outterBtn{
        background: var(--color-secondary-light) !important;
    }
    `,
  },
  alternateOutline: {
    base: `
      position: relative;
      .outterBtn{
        background: var(--color-grey-4);
        .innerBtn{
          background: var(--color-white) !important;
          color: var(--color-grey-4) !important;
        }
      }
      
      a {
        color: var(--color-grey-4) !important;
      }
    
      
    `,
    hover: `
      
      a {
        color: var(--color-primary) !important;
      }
      .outterBtn{
        background: var(--color-primary-light) !important;
        .innerBtn{
          color: var(--color-primary) !important;
        }
      }
    `,
    active: `
      
      a {
        color: var(--color-primary-dark) !important;
      }
      .outterBtn{
        background: var(--color-primary-dark) !important;
        .innerBtn{
          color: var(--color-primary-dark) !important;
        }
      }
    `,
  },
  link: {
    base: `
        height: auto !important;
        font-weight: 500;
        & a, & span{
         font-size: 1rem !important;
         line-height:1.5rem !important;
        }
        letter-spacing: 0;
        
        text-transform: none;
      
    `,
    active: `
      color: var(--color-secondary-dark);
    `,
    hover: `
      color: var(--color-link-hover);
      cursor: pointer;
      transition: color .5s;
      text-decoration: underline;
    `,
  },
  white: {
    base: `
      .outterBtn{
        background: var(--color-white) !important;
      }
      color: var(--color-grey-4) !important;
      a {
        color: var(--color-grey-4) !important;
      }
    `,
    hover: `
    .outterBtn{
      background: var(--color-grey-1) !important;
    }
    `,
    active: `
      .outterBtn{
        background: var(--color-secondary-light) !important;
      }
    `,
  },
  whiteOutline: {
    base: `
      position: relative;
      .outterBtn{
        background: transparent;
        .innerBtn{
          border: 3px solid var(--color-white) !important;
          background: transparent;
        }
      }
      color: var(--color-white) !important;
      a {
        color: var(--color-white) !important;
      }
      
    `,
    hover: `
    .outterBtn{
      background: var(--color-primary-light);
    }
    `,
    active: `
    .outterBtn{
      background: var(--color-primary-dark);
    }
    `,
  },
}

type AlignmentType = "center" | "right" | "left"

const getJustifyContentFromAlign = (align: AlignmentType) => {
  switch (align) {
    case "right":
      return "flex-end"
    case "left":
      return "flex-start"
    case "center":
      return "center"
    default:
      return "center"
  }
}

export const Buttons = styled.div<{
  margin?: number
  align?: "center" | "right" | "left"
}>`
  margin-top: ${({ margin }) =>
    margin !== null && margin !== undefined ? `${margin}px` : "40px"};
  display: flex;
  vertical-align: center;
  text-align: center;
  align-items: center;
  justify-content: ${({ align }) =>
    align ? getJustifyContentFromAlign(align) : "center"};
  flex-wrap: wrap;
  > .btn {
    margin-right: 2rem;
    margin-bottom: 2rem;
  }
`

const heroHeight = 4
const regularHeight = 3.6
const controlHeight = 1.75

const heroPadding = 1.75
const regularPadding = 1.5
const controlPadding = 0.5

const getBtnStyle = (btnProps: BtnStyleProps) => `
  font-family: var(--font-family-header);
  font-weight:700;
  letter-spacing: ${btnProps.size !== "control" ? "0.11875rem" : "0.056rem"};
  opacity: ${btnProps.disabled ? "0.4" : "1"};
  
  border: 0px solid transparent;
  border-radius: 5rem;

  

  display: flex;
  position: relative;
  text-transform: uppercase;
  
  
  
  a, span, a span, span span{
    text-decoration:none;
    
    font-size: ${
      btnProps.size !== "control" ? "1.1875rem" : "0.80rem"
    } !important;

    @media ${devices.mobile} {
      font-size: ${btnProps.size !== "control" ? "1rem" : "0.70rem"} !important;
    }
  }
  
  
  transition: all 0.75s ease;
  .outterBtn{
    padding:3px;
    
    transition: all 0.75s ease;
    display: flex;
    vertical-align: center;
    text-align: center;
    align-items: center;

    cursor: pointer;
    line-height: 1.5rem;  
    border-radius: 5rem;
    .innerBtn{
      margin:0;
      transition: all 0.75s ease;
      padding:0 ${
        btnProps.size === "hero"
          ? `${heroPadding}rem`
          : btnProps.size === "control"
          ? `${controlPadding}rem`
          : `${regularPadding}rem`
      };
      line-height: ${
        btnProps.size === "hero"
          ? `${heroHeight}rem`
          : btnProps.size === "control"
          ? `${controlHeight}rem`
          : `${regularHeight}rem`
      };
      @media ${devices.mobile} {
        padding:0 ${
          btnProps.size === "hero"
            ? `${heroPadding * 0.75}rem`
            : btnProps.size === "control"
            ? `${controlPadding * 0.75}rem`
            : `${regularPadding * 0.75}rem`
        };
        line-height: ${
          btnProps.size === "hero"
            ? `${heroHeight * 0.8}rem`
            : btnProps.size === "control"
            ? `${controlHeight * 0.8}rem`
            : `${regularHeight * 0.8}rem`
        };
      }
     
      white-space: nowrap;
      border-radius: 5rem;
      
      vertical-align:middle;
      } !important;
    }
  }

  ${btnDefs[btnProps.btntype].base}


  :hover {
    text-decoration:none;
    color: var(--color-white);
    ${btnProps.disabled ? "" : btnDefs[btnProps.btntype].hover}
  }
  
  :focus, :active{
    text-decoration:none;
    ${btnDefs[btnProps.btntype].active}
    outline: none;
  }
`

export const LinkBtnBase = styled(props => <Link {...props} />)`
  ${props => getBtnStyle(props)}
`

const BtnSubmit = styled(props => <button {...props} />)`
  background-color: transparent;
  ${props => getBtnStyle(props)}
`
export const ButtonBase = (button: ButtonProps) => {
  const size = button.size || "normal"
  let id = button.id ? `${button.id}` : `${button.title}`
  id += button.sectionId ? `_${button.sectionId}` : "_x"
  button.title && button.id
  id = id.split(" ").join("_").toLowerCase()

  const baseSpan =
    button.type === "link" ? (
      <span>
        {button.icon && (
          <>
            <Icon
              iconType="Solid"
              iconName={button.icon}
              size={size === "hero" ? "2x" : "sm"}
            />
            &emsp;
          </>
        )}
        {button.title}
      </span>
    ) : (
      <span className="outterBtn">
        <span className="innerBtn">
          {button.icon && (
            <Icon
              iconType="Solid"
              iconName={button.icon}
              size={size === "hero" ? "2x" : "sm"}
            />
          )}
          {button.icon && button.title && (
            <span style={{ marginRight: "10px" }}> </span>
          )}
          <span className="text">{button.title}</span>
        </span>
      </span>
    )
  return baseSpan
}
export const Button = (button: ButtonProps) => {
  const link: ButtonLink | undefined = button.link && button.link[0]

  const size = button.size || "normal"
  let id = button.id ? `${button.id}` : `${button.title}`
  id += button.sectionId ? `_${button.sectionId}` : "_x"
  button.title && button.id
  id = id.split(" ").join("_").toLowerCase()

  const baseSpan = ButtonBase(
    link && link.navItemName
      ? { ...button, title: link.navItemName }
      : link && link.linkText
      ? { ...button, title: link.linkText }
      : button
  )

  if (link && link.product) {
    return (
      <LinkBtnBase
        id={id}
        aria-label={button.title}
        btntype={button.type}
        size={size}
        disabled={button.disabled || false}
        className={`btn ${button.className}`}
        to={link.addToCart ? "/store/checkout" : `${link.product.slug}`}
        data-testid={button.testId}
      >
        <CartButton aria-label={button.title} {...button}>
          {baseSpan}
        </CartButton>
      </LinkBtnBase>
    )
  } else if (button.submit) {
    return (
      <BtnSubmit
        id={id}
        type="submit"
        aria-label={button.title}
        btntype={button.type}
        size={size}
        disabled={button.disabled || false}
        className={`btn ${button.className}`}
        data-testid={button.testId}
      >
        {baseSpan}
      </BtnSubmit>
    )
  }

  if (link && link.quizId) {
    return <Quiz {...button} baseSpan={baseSpan} />
  }

  let url =
    button.disabled === true
      ? ""
      : link && link.url
      ? link.url
      : link && link.navItemPage
      ? link.navItemPage.slug
      : link && link.navItemLandingPage
      ? link.navItemLandingPage.slug
      : ""

  return (
    <LinkBtnBase
      id={id}
      aria-label={button.title}
      to={url || ""}
      onClick={d => {
        if (button.disabled === true) {
          return
        }
        if (button.gTagEvent && window?.gtag) {
          window.gtag("event", button.gTagEvent)
        }
        if (button.onClick) {
          button.onClick(d)
        }
      }}
      btntype={button.type}
      size={size}
      disabled={button.disabled || false}
      className={`btn ${button.className}`}
    >
      {baseSpan}
    </LinkBtnBase>
  )
}
