import React, { FC } from "react"
import Container from "@material-ui/core/Container"
import styled from "styled-components"

interface ContainerProps {
  padding?: string
  align?: "left" | "right" | "justify" | "center"
  color?: string
  borderradius?: string
  height?: string
  maxWidth?: "xs" | "sm" | "md" | "lg" | undefined
}

export const Con = styled(Container)<ContainerProps>`
  padding: ${({ padding }) => `${padding}`};
  text-align: ${({ align }) => `${align}`};
  background-color: ${({ color }) => (color ? `${color}` : "transparent")};
  border-radius: ${({ borderradius }) => (borderradius ? borderradius : "0")};
  position: relative;
  height: ${({ height }) => (height ? height : "auto")};
`

const PaddedContainer: FC<ContainerProps> = ({
  padding = "3rem 1rem",
  align = "left",
  color = undefined,
  children,
  borderradius,
  height,
  maxWidth,
}) => {
  return (
    <Con
      padding={padding}
      align={align}
      color={color}
      borderradius={borderradius}
      height={height}
      maxWidth={maxWidth}
    >
      {children}
    </Con>
  )
}

export default PaddedContainer
