export const isClient = typeof window === "object"

export const formatSensaiUrl = (url: string) => {
  const isSensai = url && (url as String).startsWith("https://sens.ai")
  let formatedUrl = isSensai || url.indexOf("/") === 0 ? url : `/${url}`
  formatedUrl =
    formatedUrl === "/" || formatedUrl.endsWith("/")
      ? formatedUrl
      : `${formatedUrl}/`
  return formatedUrl
}

export const formatDate = ({
  date,
  fullMonth,
}: {
  date: Date
  fullMonth: boolean
}): string => {
  const dateStr = new Intl.DateTimeFormat("en", {
    month: fullMonth ? "long" : "short",
    day: "2-digit",
    year: "numeric",
  }).format(date)
  return dateStr
}

export const formatCurrencyString = (
  {
    value,
    currency,
  }: {
    value: number
    currency: string
  },
  renderDollar = true
) => {
  const language = currency === "CAD" ? "en-CA" : "en-US"

  const numberFormat = new Intl.NumberFormat(language, {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const parts = numberFormat.formatToParts(value)
  let zeroDecimalCurrency = true

  for (let i = 0; i < parts.length; i += 1) {
    const part = parts[i]
    if (part.type === "decimal") {
      zeroDecimalCurrency = false
      break
    }
  }

  let val = zeroDecimalCurrency ? value : value / 100

  if (!renderDollar) {
    return numberFormat
      .format(parseFloat(val.toFixed(2)))
      .replace(/\D00(?=\D*$)/, "")
      .replace("$", "")
  }

  return numberFormat
    .format(parseFloat(val.toFixed(2)))
    .replace(/\D00(?=\D*$)/, "")
}

export const getRandomNum = (min: number, max: number): number => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const EU_COUNTRIES = [
  "DNK",
  "EST",
  "FIN",
  "FRA",
  "DEU",
  "GRC",
  "HUN",
  "IRL",
  "ITA",
  "LVA",
  "LTU",
  "LUX",
  "MLT",
  "NLD",
  "POL",
  "PRT",
  "ROU",
  "SVK",
  "SVN",
  "ESP",
  "SWE",
]
export const isInEU = (countryCode: string) =>
  EU_COUNTRIES.indexOf(countryCode) !== -1
