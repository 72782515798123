import React, { useEffect, useState } from "react"
import styled from "styled-components"
import MainNav from "./nav/MainNav"

interface HeaderProps {
  readonly hasHero: boolean
  scrolled: boolean
  id?: string
}
interface HeaderStyleProps {
  readonly scrolled: boolean
  readonly hasHero: boolean
}

const handleBackground = (scrolled: boolean, hasHero: boolean) => {
  const alpha = scrolled ? 1 : 0
  if (hasHero) {
    return `rgba(255, 255, 255, ${alpha})`
  } else {
    return "var(--color-white)"
  }
}

const StyledHeader = styled.header<HeaderStyleProps>`
  padding: 15px 0;
  width: 100vw;
  background-color: ${({ scrolled, hasHero }) =>
    handleBackground(scrolled, hasHero)};
  display: block;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  transition: background-color 1s;
  z-index: 1000;
`

const StyledHeaderBase = styled.div<{ scrolled: boolean }>`
  height: 1px;
  background: linear-gradient(
    8deg,
    var(--color-teal) 0%,
    var(--color-purple) 100%
  );
  opacity: ${({ scrolled }) => (scrolled ? "1" : "0")};
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  transition: opacity 3s;
  z-index: 100;
`

const Spacer = styled.div<{ hasHero: boolean }>`
  padding: ${({ hasHero }) => !hasHero && "40px"};
`

const Header = ({ hasHero, scrolled }: HeaderProps) => {
  return (
    <>
      <StyledHeader scrolled={scrolled} hasHero={hasHero}>
        <MainNav scrolled={scrolled || !hasHero} />
        <StyledHeaderBase scrolled={scrolled || !hasHero} />
      </StyledHeader>
      <Spacer hasHero={hasHero} />
    </>
  )
}

export default Header
