import { Dispatch } from "react"

import { setLs } from "../../utils/localStorage"
import { Action, CheckoutActionKind, State } from "../types/checkoutTypes"

export const removeFromCart = (
  cartItemId: string,
  state: State,
  dispatch: Dispatch<Action>
) => {
  const { cartItems } = state
  const existingItem = cartItems.find(item => item.productId === cartItemId)

  if (!existingItem) return

  // Update global state
  dispatch({
    type: CheckoutActionKind.REMOVE_FROM_CART,
    payload: { cartItemId },
  })

  // Persist to localstorage
  const updatedCartItems = state.cartItems.filter(
    item => item.productId !== cartItemId
  )

  setLs("cartItems", updatedCartItems)
}
