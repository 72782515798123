import React from "react"
import styled from "styled-components"
import Icon from "./Icon"

interface IconBtnStyleProps {
  r?: number
  g?: number
  b?: number
  a?: number
  gradient?: boolean
  size: "sm" | "md" | "lg"
  icon?: string
  iconColor?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const BtnBase = styled.span<IconBtnStyleProps>`
  padding: 0;
  display: inline-block;
  position: relative;
`

const BtnRing = styled.span<IconBtnStyleProps>`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ a, r, g, b, size }) =>
    `
    -webkit-transition:transform .75s ease, width .75s ease;
    transform: scale(1.001); 
  :hover{
   transform: scale(1.1);
    
  }
  @-webkit-keyframes ripple{
    0% {-webkit-transform:scale(1); opacity:0.3; }
    75% {-webkit-transform:scale(1.35); opacity:0.25;}
    100% {-webkit-transform:scale(1.65); opacity:0;}
  }
  
  @keyframes ripple{
    0% {transform:scale(1); opacity:0.3;  }
    75% {transform:scale(1.35); opacity:0.25;}
    100% {transform:scale(1.65); opacity:0;}
  }
  &:before, &:after{
    content:'';
    position:absolute;
    display:block;
    width: ${size == "sm" ? "40px" : size === "md" ? "60px" : "105px"};
    height: ${size == "sm" ? "40px" : size === "md" ? "60px" : "105px"};
    
    border-radius:50%;
    border: 4px solid rgba(${r}, ${g}, ${b}, 1);
  }
  &:before {
    -webkit-animation: ripple 7.5s linear infinite;
    animation: ripple 7.5s linear infinite;
  }
  &:after {
    -webkit-animation: ripple 4.2s linear infinite;
    animation: ripple 4.2s linear infinite;
  }
  `}
`

const BtnIconInner = styled.span<IconBtnStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  width: ${({ size }) =>
    size == "sm" ? "40px" : size === "md" ? "60px" : "105px"};
  height: ${({ size }) =>
    size == "sm" ? "40px" : size === "md" ? "60px" : "105px"};
  background-color: ${({ r, g, b, a }) => `rgba(${r}, ${g}, ${b}, ${a})`};

  color: var(--color-white);
`

export const IconButton = (button: IconBtnStyleProps) => {
  return (
    <BtnBase {...button}>
      <BtnRing {...button} onClick={undefined}>
        <BtnIconInner {...button} onClick={undefined}>
          {button.icon && (
            <Icon
              iconType="Solid"
              iconName={button.icon}
              backgroundColor={button.gradient ? "white" : "teal"}
              brightColor={button.gradient}
              iconColor={button.iconColor}
              size={
                button.size === "sm" ? "2x" : button.size === "md" ? "2x" : "4x"
              }
            />
          )}
        </BtnIconInner>
      </BtnRing>
    </BtnBase>
  )
}
