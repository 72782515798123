import { Dispatch } from "react"
import getStripe, { getCartProduct, getStripeId } from "../../utils/stripejs"
import {
  State,
  Currency,
  Action,
  CheckoutActionKind,
} from "../types/checkoutTypes"
import { sendPixelEvent } from "../../utils/pixel"
import { klaviyoTrackBehavior } from "../../utils/klaviyo"
import { CartProduct } from "../../ts/interfaces"
import { setLs } from "../../utils/localStorage"
import { getUpdatedCartItems } from "../shared"

export const addToCart = (
  state: State,
  dispatch: Dispatch<Action>,
  quantity: number,
  stripeId: string | undefined,
  stripeTestId: string | undefined,
  sendPxEvent?: () => void,
  skipTracking?: boolean
) => {
  // Load product information
  getStripe()
  const foundStripeId = getStripeId(stripeId, stripeTestId)
  const cartProduct = getCartProduct(foundStripeId)

  if (cartProduct) {
    // Send tracking events
    if (sendPxEvent && !skipTracking) {
      sendTracking(state, cartProduct, quantity, sendPxEvent)
    }

    // Update global state
    dispatch({
      type: CheckoutActionKind.ADD_TO_CART,
      payload: { id: cartProduct.id, quantity },
    })

    // Persist to localstorage
    const updatedCartItems = getUpdatedCartItems(
      cartProduct.id,
      quantity,
      state.cartItems
    )
    setLs("cartItems", updatedCartItems)
  }
}

const sendTracking = (
  state: State,
  cartProduct: CartProduct,
  quantity: number,
  sendPxEvent: () => void
) => {
  const { currency } = state

  const price =
    (cartProduct && cartProduct.prices && cartProduct.prices[currency]) || null

  if (
    typeof window !== `undefined` &&
    cartProduct &&
    quantity &&
    state &&
    price
  ) {
    sendPixelEvent("AddToCart", sendPxEvent, {
      items: [{ ...cartProduct, quantity }],
      total: 0,
      currency,
    })

    if (window._learnq) {
      klaviyoTrackBehavior(
        "Added to Cart",
        buildKlaviyoObject(cartProduct, quantity, price.unit_amount, currency)
      )
    }
  }
}

const buildKlaviyoObject = (
  cartProduct: CartProduct,
  quantity: number,
  price: number,
  currency: Currency
) => {
  return {
    content_type: "product",
    id: cartProduct.id,
    name: cartProduct.name,
    quantity: quantity,
    ...(price && {
      value: parseFloat((price / 100.0).toFixed(2)),
    }),
    currency: currency,
  }
}
