import React, { useState } from "react"
import { Location } from "@reach/router"
import { Link, navigate } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Container from "@material-ui/core/Container"
import { getSrc } from "gatsby-plugin-image"

import { devices } from "../config/breakpoints"
import NavMenu from "./NavMenu"
import { NavItem } from "./NavItem"

import { NavItemProps, NavPage } from "../../ts/interfaces"
import { Button } from "../ui/Button"
import { Hidden } from "@material-ui/core"

import NavDrawer from "./NavDrawer"
import { useLogout } from "../../hooks/useLogout"
import { useAuthState } from "../../contexts/authContext"
import NavButtons from "./NavButtons"

import loadable from "@loadable/component"
import { ContactsOutlined } from "@material-ui/icons"
const UserMenu = loadable(
  () => import(/* webpackPrefetch: true */ "./UserMenu")
)
const CartPreview = loadable(
  () => import(/* webpackPrefetch: true */ "../cart/CartPreview")
)

interface NavProps {
  readonly scrolled: boolean
}

export interface NavSections {
  length: number
  groups: Record<string, NavSection>
}

export interface NavSection {
  navItemPage?: NavPage
  navItemLandingPage?: NavPage
  navItemName: string
  url: string
  pages: Page[]
}
export interface Page {
  navItemPage?: NavPage
  navItemLandingPage?: NavPage
  navItemName: string
  url?: string
}

const NavBar = styled.nav<NavProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  a {
    color: ${props =>
      props.scrolled ? "var(--color-grey-4)" : "var(--color-grey-4)"};
  }
`

const NavDrawerContainer = styled.div`
  display: none;
  @media ${devices.mobile} {
    display: block;
  }
`

const NavItems = styled.ul`
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  /* margin-left: 100px; */
  @media ${devices.mobile} {
    display: none;
  }
`

const NavExtras = styled.ul`
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* flex: 1; */
  a {
    :hover {
      text-decoration: none;
    }
  }
`

const LogoContainer = styled.div``
const MainNav = ({ scrolled }: NavProps) => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        navigations {
          id
          location

          pages {
            __typename
            ... on STRAPI_ComponentNavigationMainNav {
              navItemPage {
                id
                title
                slug
              }
              navItemLandingPage {
                id
                title
                slug
              }
              navItemName
              sectionGroup
            }
            ... on STRAPI_ComponentNavigationHyperlink {
              url
              navItemName
              sectionGroup
            }
          }

          button {
            id
            title
            type
            link {
              __typename
              ... on STRAPI_ComponentNavigationMainNav {
                navItemPage {
                  id
                  slug
                }
                navItemLandingPage {
                  id
                  slug
                }
              }
              ... on STRAPI_ComponentNavigationProduct {
                id
                addToCart
                product {
                  id
                  stripeId
                  stripeTestId
                  slug
                }
              }
              ... on STRAPI_ComponentNavigationHyperlink {
                url
              }
              ... on STRAPI_ComponentZonesQuiz {
                id
                quizId
              }
            }
          }
        }

        siteConfig {
          logo {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: FIXED
                  height: 135
                  width: 480
                )
              }
            }
          }
          logoOnDark {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: FIXED
                  height: 135
                  width: 480
                )
              }
            }
          }
        }
      }
    }
  `)

  const { siteConfig } = data.strapi
  const navigation =
    data.strapi.navigations &&
    data.strapi.navigations.find(nav => nav.location === "header")

  // const logo = scrolled
  //   ? siteConfig && siteConfig.logo && siteConfig.logo
  //   : siteConfig && siteConfig.logoOnDark && siteConfig.logoOnDark
  const logo = siteConfig && siteConfig.logo && siteConfig.logo

  const sections: NavSections = { length: 0, groups: {} }
  if (navigation && navigation.pages && navigation.pages.length > 0) {
    navigation.pages.forEach(
      ({
        navItemName,
        navItemPage,
        sectionGroup,
        navItemLandingPage,
        url,
      }: NavItemProps) => {
        const group = sectionGroup !== undefined ? `g${sectionGroup}` : "999999"

        if (sections.groups[group]) {
          sections.groups[group].pages.push({
            navItemName,
            navItemPage,
            navItemLandingPage,
            url,
          })
        } else {
          sections.groups[group] = {
            navItemName,
            navItemPage,
            url,
            pages: [{ navItemName, navItemPage, navItemLandingPage, url }],
          }
          sections.length += 1
        }
      }
    )
  }

  const state = useAuthState()
  const { token } = state

  let userMenuNavItems: { navItemPage?: NavPage; navItemName: string }[]
  if (!token || !state.isLoggedIn()) {
    userMenuNavItems = [
      {
        navItemPage: {
          id: "main-nav-login",
          title: "Login",
          slug: "login",
          pages: [],
        },
        navItemName: "Login",
      },
    ]
  } else {
    const logout = useLogout("/loggedout")
    userMenuNavItems = [
      {
        navItemPage: {
          id: "user-account-link",
          title: "My Account",
          drawerSpacer: true,
          slug: "user",
          pages: [],
        },
        navItemName: "My Account",
      },
      {
        navItemPage: {
          id: "user-order-history",
          title: "Order History",
          slug: "user/orders",
          pages: [],
        },
        navItemName: "Order History",
      },
      {
        navItemPage: {
          id: "user-logout",
          title: "Logout",
          slug: "loggedout",
          pages: [],
          onClick: async (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault()
            logout()
          },
        },
        navItemName: "Logout",
      },
    ]
  }

  const navDrawerSections = { ...sections }
  navDrawerSections.groups["USER"] = {
    navItemName: state.isLoggedIn() ? state.firstName || "Acccount" : "Account",
    pages: userMenuNavItems,
  }
  navDrawerSections.length += 1

  const isOnDropdownPage = (pages: Page[]) => {
    if (typeof window !== "undefined") {
      return pages
        .map(p => p.navItemPage?.slug)
        .some(p => p && window.location.pathname.endsWith(p))
    }
    return false
  }

  return sections.length === 0 ? null : (
    <Container>
      <NavBar scrolled={scrolled}>
        <Link to="/" title="Sens.ai - Home" aria-label="Sens.ai - Home">
          <LogoContainer>
            <img
              alt="Sens.ai"
              src={getSrc(
                logo &&
                  logo.imageFile &&
                  logo.imageFile.childImageSharp.gatsbyImageData
              )}
              height="45"
              width="160"
            />
          </LogoContainer>
        </Link>

        <NavItems>
          {Object.keys(sections.groups).map(key => {
            if (key === "length" || key === "USER") {
              return
            }

            const pages = sections.groups[key].pages
            const {
              navItemName,
              navItemPage,
              navItemLandingPage,
              url,
            } = sections.groups[key]
            if (pages.length > 1) {
              return (
                <NavItem
                  key={
                    (navItemPage
                      ? navItemPage?.id
                      : navItemLandingPage
                      ? navItemLandingPage.id
                      : navItemName + "-link") +
                    "_" +
                    key
                  }
                  scrolled={scrolled}
                  isActive={isOnDropdownPage(pages)}
                >
                  <NavMenu title={navItemName} items={pages} keyId={key} />
                </NavItem>
              )
            }

            if (navItemPage) {
              return (
                <NavItem key={navItemPage.id + "_" + key} scrolled={scrolled}>
                  <Link
                    to={navItemPage.slug ? `/${navItemPage.slug}` : ""}
                    activeClassName="active"
                    id={(navItemPage?.id ?? navItemName) + "_" + key}
                    title={navItemName}
                  >
                    {navItemName}
                  </Link>
                </NavItem>
              )
            }
            if (url) {
              return (
                <NavItem
                  key={(navItemPage?.id ?? navItemName) + "_" + key}
                  scrolled={scrolled}
                >
                  <a
                    href={url}
                    id={(navItemPage?.id ?? navItemName) + "_" + key}
                    title={navItemName}
                  >
                    {navItemName}
                  </a>
                </NavItem>
              )
            }
          })}
        </NavItems>

        <NavExtras>
          {/* {navigation.button && (
            <Hidden xsDown>
              <Button
                {...navigation.button}
                size="control"
                sectionId="header_nav"
              />
            </Hidden>
          )} */}
          {/* {(!token || !state.isLoggedIn()) && ( */}
          <NavButtons
            scrolled={scrolled}
            loggedIn={token && state.isLoggedIn()}
            navButton={navigation.button}
          />
          {/* )} */}
          <Link to="/store/checkout" title="Checkout" aria-label="Navigation">
            <CartPreview />
          </Link>
          {token && state.isLoggedIn() && (
            <UserMenu
              scrolled={scrolled}
              navItems={userMenuNavItems}
              hideMobile={true}
            />
          )}

          <NavDrawerContainer>
            <NavDrawer
              sections={navDrawerSections}
              scrolled={scrolled}
              navButton={navigation.button}
              isOnDropdownPage={isOnDropdownPage}
            />
          </NavDrawerContainer>
        </NavExtras>
      </NavBar>
    </Container>
  )
}

export default MainNav
