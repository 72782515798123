import { ApolloError } from "@apollo/client"
import { navigate } from "gatsby"

export const CheckErrors = (
  error?: ApolloError,
  authRequired?: boolean
): string | undefined => {
  let errMsg
  if (error?.graphQLErrors && error?.graphQLErrors.length > 0) {
    error?.graphQLErrors.forEach(err => {
      if (err.extensions?.code === "UNAUTHENTICATED" && authRequired) {
        //The user is not authenticated and needs to be
        console.log(err)
        //navigate("/login")
      } else if (err.extensions?.code === "UNAUTHENTICATED") {
        //the user is not athenicated but we are not requiring it... pass along the message
        errMsg = err.message
      } else if (err.extensions?.exception?.name === "ValidationError") {
        errMsg = err.message
      } else if (err.extensions?.code === "GRAPHQL_VALIDATION_FAILED") {
        errMsg = err.message
      }
    })
  } else if (
    error?.networkError?.result?.errors &&
    error?.networkError?.result?.errors.length > 0
  ) {
    error?.networkError?.result?.errors.forEach(err => {
      if (err.extensions?.code === "GRAPHQL_VALIDATION_FAILED") {
        errMsg = err.message
      }
    })
  }

  return errMsg
}
